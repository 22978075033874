<template>
  <div class="text-center mt-5">
    <spinner/>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner";
import {mapActions} from "vuex";
export default {
  name: "Logout",
  components: {Spinner},

  methods: {
    ...mapActions('accounts', ['logout'])
  },

  created() {
    this.logout();
  }

}
</script>
